import axios from "axios";

const instance = axios.create({
  baseURL: "https://nbs.rs/QRcode/api/qr/v1/",
  withCredentials: false,
  headers: {
    "Content-type": "text/plain"
  },
});

export default instance;